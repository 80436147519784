import { LocalizationOptions } from './localization-options';

export class LocalizationDefaults {
  get(showPlaceholders: boolean): LocalizationOptions {
    return getDefaultSkinLocalization(showPlaceholders);
  }
}

function getDefaultSkinLocalization(showPlaceholders: boolean): LocalizationOptions {
  const result = new LocalizationOptions();
  const label = result.label;
  label.cardholderName = 'Cardholder Name';
  label.cardNumber = 'Credit Card';
  label.expiration = 'Expiration';
  label.cvv = 'Security Code';
  label.postalCode = 'Zip/Postal Code';
  label.routingNumber = 'Routing Number';
  label.accountNumber = 'Account Number';
  label.accountHolder = 'Account Holder';
  label.accountType = 'Account Type';
  label.companyName = 'Company Name';
  label.emailAddress = 'Email Address';
  label.countryCode = 'Country';
  label.fullName = 'Full Name';

  if (showPlaceholders) {
    const placeholder = result.placeholder;
    placeholder.cardholderName = 'Cardholder Name';
    placeholder.cardNumber = 'Key enter credit card';
    placeholder.expiration = 'mm/yy';
    placeholder.cvv = 'CVV';
    placeholder.postalCode = '12345';
    placeholder.routingNumber = 'Routing Number';
    placeholder.accountNumber = 'Account Number';
    placeholder.accountHolder = 'Account Holder';
    placeholder.accountType = '';
    placeholder.companyName = 'Company Name';
    placeholder.emailAddress = 'Email Address';
    placeholder.countryCode = 'Country';
    placeholder.fullName = 'Full Name';
  }

  const validation = result.validation;
  validation.invalidCardholderName = 'Cardholder Name is invalid';
  validation.invalidCardNumber = 'Credit Card is an invalid card number';
  validation.invalidCardType = 'Credit Card is an invalid card type';
  validation.invalidExpiration = 'Expiration is invalid';
  validation.invalidCvv = 'Security Code is invalid';
  validation.invalidPostalCode = 'Zip/Postal Code is invalid';
  validation.invalidRoutingNumber = 'Routing Number is invalid';
  validation.invalidAccountNumber = 'Account Number is invalid';
  validation.invalidAccountHolder = 'Account Holder is invalid';
  validation.invalidAccountType = 'Account Type is invalid';
  validation.invalidCompanyName = 'Company Name is invalid';
  validation.invalidEmailAddress = 'Email Address is invalid';
  validation.invalidCountryCode = 'Invalid Country';
  validation.invalidFullName = 'Full Name is invalid';

  const required = validation.required;
  required.cardholderName = 'Cardholder Name is required';
  required.cardNumber = 'Credit Card is required';
  required.expiration = 'Expiration is required';
  required.cvv = 'Security Code is required';
  required.postalCode = 'Zip/Postal Code is required';
  required.routingNumber = 'Routing Number is required';
  required.accountNumber = 'Account Number is required';
  required.accountHolder = 'Account Holder is required';
  required.accountType = 'Account Type is required';
  required.companyName = 'Company Name is required';
  required.emailAddress = 'Email Address is required';
  required.countryCode = 'Country is required';
  return result;
}
