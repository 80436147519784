import { LocalizationByField } from '../localization-by-field';
import { LocalizationDefaults } from '../localization-defaults';
import { LocalizationOptions } from '../localization-options';
import { LocalizationValidation } from '../localization-validation';
import { Parser } from '../parser';
import { Settings } from '../settings';
import { LocalizationParserOptions } from './localization-parser-options';
import { RawInitOptionsLocalization } from './raw-init-options-localization';
import { RawInitOptionsLocalizationByField } from './raw-init-options-localization-by-field';
import { RawInitOptionsLocalizationValidation } from './raw-init-options-localization-validation';

export class LocalizationParser
  implements Parser<RawInitOptionsLocalization, LocalizationOptions, LocalizationParserOptions> {
  constructor(private readonly _settings: Settings, private readonly _localizationDefaults: LocalizationDefaults) {}

  parse(
    rawInitOptionsLocalization: RawInitOptionsLocalization | undefined,
    options: LocalizationParserOptions,
  ): LocalizationOptions {
    const result = this._localizationDefaults.get(options.showPlaceholders);

    if (!rawInitOptionsLocalization) {
      return result;
    }

    result.label = this._parseLocalizationByField(rawInitOptionsLocalization.label, result.label);

    if (options.showPlaceholders) {
      result.placeholder = this._parseLocalizationByField(rawInitOptionsLocalization.placeholder, result.placeholder);
    }

    result.validation = this._parseLocalizationValidation(rawInitOptionsLocalization.validation, result.validation);

    return result;
  }

  private _parseLocalizationByField(
    rawInitOptionsLocalizationByField: RawInitOptionsLocalizationByField | undefined,
    defaults: LocalizationByField,
  ): LocalizationByField {
    if (!rawInitOptionsLocalizationByField) {
      return defaults;
    }

    const result = new LocalizationByField();

    this._settings.fieldNames.forEach(field => {
      const localizedText = (rawInitOptionsLocalizationByField as any)[field] as string | undefined;
      (result as any)[field] = localizedText || (defaults as any)[field];
    });

    return result;
  }

  private _parseLocalizationValidation(
    rawInitOptionsLocalizationValidation: RawInitOptionsLocalizationValidation | undefined,
    defaults: LocalizationValidation,
  ): LocalizationValidation {
    if (!rawInitOptionsLocalizationValidation) {
      return defaults;
    }

    const result = new LocalizationValidation();

    this._settings.validationNames.forEach(validation => {
      const localizedText = (rawInitOptionsLocalizationValidation as any)[validation] as string | undefined;
      (result as any)[validation] = localizedText || (defaults as any)[validation];
    });

    if (rawInitOptionsLocalizationValidation.required) {
      const resultRequired = result.required;
      const customDefaultRequiredText = rawInitOptionsLocalizationValidation.required.default;
      const defaultRequired = defaults.required;

      this._settings.fieldNames.forEach(field => {
        const localizedText = (rawInitOptionsLocalizationValidation.required as any)[field] as string | undefined;
        (resultRequired as any)[field] = localizedText || customDefaultRequiredText || (defaultRequired as any)[field];
      });
    } else {
      result.required = defaults.required;
    }

    return result;
  }
}
