import { Parser } from '../parser';
import { WalletOptions } from '../wallet-options';
import {RawInitOptionsWallet} from "./raw-init-options-wallet";

export class WalletOptionsParser implements Parser<RawInitOptionsWallet, WalletOptions> {

  parse(input: RawInitOptionsWallet | undefined): WalletOptions {

    const result = new WalletOptions();

    if (!input) {
      return result;
    }

    result.amount = input?.amount
    result.enabled = input?.enabled

    return result;
  }
}
