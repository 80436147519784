import { Parser } from '../parser';
import { RawInitOptionsKlarna } from './raw-init-options-klarna';
import { KlarnaOptions } from '../klarna-options';

export class KlarnaOptionsParser implements Parser<RawInitOptionsKlarna, KlarnaOptions> {

  parse(input: RawInitOptionsKlarna | undefined): KlarnaOptions {

    const result = new KlarnaOptions();

    if (!input) {
      return result;
    }

    result.returnUrl = input?.returnUrl
    result.amount = input?.amount

    return result;
  }
}
