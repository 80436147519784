import { $enum } from 'ts-enum-util';

export class NonNgEnumHelpers {
  /**
   * Gets an array of all values assigned in a TypeScript enumeration
   * @param enumObject Enumeration Object to get values from
   */
  values<T>(enumObj: T): any[] {
    return $enum(enumObj as any).getValues();
  }

  /**
   * Finds the value from a TypeScript enumeration
   * @param enumObject Enumeration Object to get key from
   * @param value Value to find in the enumeration
   */
  getValueFromEnum<T>(enumObj: T, value: any): T[keyof T] | undefined {
    const keys = Object.keys(enumObj);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const keyValue = (enumObj as any)[key];

      if (keyValue === value) {
        return (keyValue as any) as T[keyof T];
      }
    }
  }

  /**
   * Finds the key of a given value from a TypeScript enumeration
   * @param enumObject Enumeration Object to get key from
   * @param value Value to find in the enumeration
   */
  getKeyFromValue<T>(enumObj: T, value: any): T[keyof T] | undefined {
    const keys = Object.keys(enumObj);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const keyValue = (enumObj as any)[key];

      if (keyValue === value) {
        return (key as any) as T[keyof T];
      }
    }
  }
}
