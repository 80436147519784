import { HostMessage } from '../shared/host-message';
import { HostMessageType } from '../shared/host-message-type';
import { InitMessage } from '../shared/host-messages/init-message';
import { SetModeMessage } from '../shared/host-messages/set-mode-message';
import { InitOptions } from '../shared/init-options/init-options';
import { MessageQueue } from '../shared/message-queue';
import { Mode } from '../shared/mode';
import { SendMessageFn } from '../shared/send-message-fn';

export class Messenger {
  constructor(private readonly _messageQueue: MessageQueue) {}

  init(initOptions: InitOptions, sendMessageFn: SendMessageFn) {
    sendMessageFn(new HostMessage<InitMessage>(HostMessageType.Init, new InitMessage(initOptions)));
  }

  resetForm() {
    this._messageQueue.add(new HostMessage(HostMessageType.ResetForm));
  }

  setFormToSubmitted() {
    this._messageQueue.add(new HostMessage(HostMessageType.SetFormToSubmitted));
  }

  setMode(mode: Mode) {
    this._messageQueue.add(
      new HostMessage<SetModeMessage>(HostMessageType.SetMode, {
        mode: mode,
      }),
    );
  }

  setSwiperFocus() {
    this._messageQueue.add(new HostMessage(HostMessageType.SetSwiperFocus));
  }

  submitForm() {
    this._messageQueue.add(new HostMessage(HostMessageType.SubmitForm));
  }
}
