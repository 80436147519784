import { LocalizationByField } from './localization-by-field';

export class LocalizationValidation {
  invalidCardholderName: string;
  invalidCardNumber: string;
  invalidCardType: string;
  invalidExpiration: string;
  invalidCvv: string;
  invalidPostalCode: string;
  invalidRoutingNumber: string;
  invalidAccountNumber: string;
  invalidAccountHolder: string;
  invalidAccountType: string;
  invalidCompanyName: string;
  invalidEmailAddress: string;
  invalidCountryCode: string;
  invalidFullName: string;
  required: LocalizationByField;

  constructor() {
    this.invalidCardholderName = '';
    this.invalidCardNumber = '';
    this.invalidCardType = '';
    this.invalidExpiration = '';
    this.invalidCvv = '';
    this.invalidPostalCode = '';
    this.invalidRoutingNumber = '';
    this.invalidAccountNumber = '';
    this.invalidAccountHolder = '';
    this.invalidAccountType = '';
    this.invalidCompanyName = '';
    this.invalidEmailAddress = '';
    this.invalidCountryCode = '';
    this.invalidFullName = '';
    this.required = new LocalizationByField();
  }
}
