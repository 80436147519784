import { StyleDictionary } from './style-dictionary';

export class StyleDictionaryByPseudoName {
  base: StyleDictionary;
  focus: StyleDictionary;
  hover: StyleDictionary;
  placeholder: StyleDictionary;

  constructor() {
    this.base = new StyleDictionary();
    this.focus = new StyleDictionary();
    this.hover = new StyleDictionary();
    this.placeholder = new StyleDictionary();
  }
}
