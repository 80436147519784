// Need this to not be a library import because it's a webpack build (no tree shaking)
// eslint-disable-next-line
import { NonNgEnumHelpers } from 'libs/core/common/src/lib/non-ng/enum-helpers';
import { FrameMessage } from '../shared/frame-message';
import { FrameMessageType } from '../shared/frame-message-type';
import { Parser } from '../shared/parser';

export class MessageParser implements Parser<MessageEvent, FrameMessage | null> {
  private readonly _frameMessageTypes: string[];

  constructor(enumHelpers: NonNgEnumHelpers) {
    this._frameMessageTypes = enumHelpers.values(FrameMessageType);
  }

  parse<T>(event: MessageEvent): FrameMessage<T> | null {
    if (!event.data || !event.data.type) {
      return null;
    }

    if (this._frameMessageTypes.indexOf(event.data.type) === -1) {
      throw Error(
        `Invalid frame message type "${event.data.type}". Valid types are ${this._frameMessageTypes.join(', ')}`,
      );
    }

    return new FrameMessage<T>(event.data.type, event.data.body);
  }
}
