import { StyleDictionaryByPseudoName } from './style-dictionary-by-pseudo-name';

export class StyleDictionaryByPseudoNameByField {
  cardholderName: StyleDictionaryByPseudoName;
  cardNumber: StyleDictionaryByPseudoName;
  expiration: StyleDictionaryByPseudoName;
  cvv: StyleDictionaryByPseudoName;
  postalCode: StyleDictionaryByPseudoName;
  routingNumber: StyleDictionaryByPseudoName;
  accountNumber: StyleDictionaryByPseudoName;
  accountHolder: StyleDictionaryByPseudoName;
  accountType: StyleDictionaryByPseudoName;
  companyName: StyleDictionaryByPseudoName;

  constructor() {
    this.cardholderName = new StyleDictionaryByPseudoName();
    this.cardNumber = new StyleDictionaryByPseudoName();
    this.expiration = new StyleDictionaryByPseudoName();
    this.cvv = new StyleDictionaryByPseudoName();
    this.postalCode = new StyleDictionaryByPseudoName();
    this.routingNumber = new StyleDictionaryByPseudoName();
    this.accountNumber = new StyleDictionaryByPseudoName();
    this.accountHolder = new StyleDictionaryByPseudoName();
    this.accountType = new StyleDictionaryByPseudoName();
    this.companyName = new StyleDictionaryByPseudoName();
  }
}
