// Need this to not be a library import because it's a webpack build (no tree shaking)
// eslint-disable-next-line
import { NonNgEnumHelpers } from 'libs/core/common/src/lib/non-ng/enum-helpers';
import { DefaultMessageQueue } from '../shared/default-message-queue';
import { FrameMessageType } from '../shared/frame-message-type';
import { InitOptions } from '../shared/init-options/init-options';
import { LocalizationParser } from '../shared/init-options/localization-parser';
import { RawInitOptions } from '../shared/init-options/raw-init-options';
import { StyleOptionsParser } from '../shared/init-options/style-options-parser';
import { LocalizationDefaults } from '../shared/localization-defaults';
import { Settings } from '../shared/settings';
import { DefaultHostListenerRegistry } from './default-host-listener-registry';
import { Host } from './host';
import { MessageParser } from './message-parser';
import { KlarnaOptionsParser } from '../shared/init-options/klarna-options-parser';
import { WalletOptionsParser } from '../shared/init-options/wallet-options-parser';
import { EndUserInfo } from '../shared/end-user-info';

const psWindow = window as any;
const IFRAME_SRC = 'https://api.paysimple.com/pmt/v1/frame/index.html';

psWindow.paymentMethodTokenization = (rawInitOptions: RawInitOptions | undefined) => {
  if (!psWindow.MessageChannel) {
    throw new Error('MessageChannels are not supported.');
  }

  const endUserInfo: EndUserInfo = {
    hostUrl: window.location.href,
    userAgent: window.navigator.userAgent,
  };

  const settings = new Settings();
  const styleOptionsParser = new StyleOptionsParser(settings);
  const localizationDefaults = new LocalizationDefaults();
  const localizationParser = new LocalizationParser(settings, localizationDefaults);
  const klarnaOptionsParser = new KlarnaOptionsParser();
  const walletOptionsParser = new WalletOptionsParser();
  const initOptions = new InitOptions(styleOptionsParser,
    localizationParser,
    klarnaOptionsParser,
    walletOptionsParser,
    rawInitOptions,
    endUserInfo);

  const enumHelpers = new NonNgEnumHelpers();
  const messageParser = new MessageParser(enumHelpers);
  const messageQueue = new DefaultMessageQueue();
  const listenerRegistry = new DefaultHostListenerRegistry();
  const host = new Host(IFRAME_SRC, messageParser, messageQueue, listenerRegistry, initOptions);

  return {
    trigger: host.messenger,
    on: (type: FrameMessageType, callback: (body: any) => void) => host.addListener(type, callback),
    destroy: () => host.destroy(),
  };
};
