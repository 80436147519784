import { FrameMessage } from '../shared/frame-message';
import { FrameMessageType } from '../shared/frame-message-type';
import { HostMessage } from '../shared/host-message';
import { InitOptions } from '../shared/init-options/init-options';
import { MessageQueue } from '../shared/message-queue';
import { Parser } from '../shared/parser';
import { BuiltInListeners } from './built-in-listeners';
import { HostListenerCallbackContext } from './host-listener-callback-context';
import { HostListenerRegistry } from './host-listener-registry';
import { Messenger } from './messenger';

export class Host {
  private _isInitialized = false;
  private _iframe: HTMLIFrameElement | undefined;
  private readonly _messenger: Messenger;
  private _builtInListeners: BuiltInListeners;

  get messenger(): Messenger {
    return this._messenger;
  }

  constructor(
    iframeSrc: string,
    private readonly _messageParser: Parser<MessageEvent, FrameMessage | null>,
    private readonly _messageQueue: MessageQueue,
    private readonly _listenerRegistry: HostListenerRegistry,
    private readonly _initOptions: InitOptions,
  ) {
    this._builtInListeners = new BuiltInListeners(this._messageQueue, this._listenerRegistry);
    this._messenger = new Messenger(this._messageQueue);

    Host._createIframe(iframeSrc, _initOptions.container).then(iframe => {
      this._iframe = iframe;

      const messageChannel = new (window as any).MessageChannel();

      messageChannel.port1.onmessage = (event: MessageEvent) => this._handleMessage(event);
      this._messenger.init(_initOptions, initMessage => {
        this._sendMessage(initMessage, messageChannel.port2);
      });
    });
  }

  private static _createIframe(iframeSrc: string, container: HTMLElement): Promise<HTMLIFrameElement> {
    return new Promise(resolve => {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('frameBorder', '0');
      iframe.setAttribute('scrolling', 'no');
      iframe.setAttribute('allowTransparency', 'true');
      iframe.setAttribute('allow', 'payment *');
      iframe.style.width = '100%';
      iframe.src = iframeSrc;

      iframe.addEventListener('load', () => {
        resolve(iframe);
      });

      container.appendChild(iframe);
    });
  }

  destroy() {
    this._builtInListeners.destroy();

    if (this._iframe && this._iframe.parentElement) {
      this._iframe.parentElement.removeChild(this._iframe);
    }

    this._iframe = undefined;
    this._listenerRegistry.clear();
  }

  addListener(type: FrameMessageType, callback: (messageBody: any) => void) {
    this._listenerRegistry.addCustom(type, callback);
  }

  private _handleMessage(event: MessageEvent) {
    const message = this._messageParser.parse(event);

    if (this._initOptions!.debug) {
      console.log('HOST:', message);
    }

    if (!message) {
      return;
    }

    if (!this._iframe) {
      throw Error('iframe has not been initialized - cannot process message.');
    }

    if (!this._isInitialized) {
      if (message.type !== FrameMessageType.InitAck) {
        throw new Error(
          `Expected message of type "${FrameMessageType.InitAck}" but received message of type "${message.type}".`,
        );
      }

      this._messageQueue.setSendMessageFn((m: HostMessage<any>) => this._sendMessage(m));

      this._isInitialized = true;
    } else {
      const context: HostListenerCallbackContext = {
        message: message,
        messenger: this._messenger,
        iframe: this._iframe,
      };

      this._listenerRegistry.trigger(context);
    }
  }

  private _sendMessage(message: HostMessage<any>, port?: MessagePort) {
    if (!this._iframe) {
      throw Error('iframe has not been initialized - cannot send message.');
    }

    const contentWindow = this._iframe.contentWindow;

    if (contentWindow) {
      contentWindow.postMessage(message, '*', [port || new (window as any).MessageChannel().port2]);
    }
  }
}
