export class StyleDictionary {
  readonly styles: { [style: string]: string };

  constructor(styles?: { [style: string]: string }) {
    this.styles = styles || {};
  }

  add(key: string, value: string) {
    this.styles[key] = value;
  }
}
