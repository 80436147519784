export class WalletOptions {
  readonly $classname = 'WalletOptions';
  amount: number;
  enabled: boolean;

  constructor() {
    this.amount = 0;
    this.enabled = false;
  }
}
