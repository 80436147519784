export class Settings {
  get fieldNames(): string[] {
    return [
      'cardholderName',
      'cardNumber',
      'expiration',
      'cvv',
      'postalCode',
      'routingNumber',
      'accountNumber',
      'accountHolder',
      'accountType',
      'companyName',
    ];
  }

  get validationNames(): string[] {
    return [
      'invalidCardholderName',
      'invalidCardNumber',
      'invalidCardType',
      'invalidExpiration',
      'invalidCvv',
      'invalidPostalCode',
      'invalidRoutingNumber',
      'invalidAccountNumber',
      'invalidAccountHolder',
      'invalidAccountType',
      'invalidCompanyName',
    ];
  }
}
