import { Parser } from '../parser';
import { Settings } from '../settings';
import { StyleDictionary } from '../style-dictionary';
import { StyleDictionaryByPseudoName } from '../style-dictionary-by-pseudo-name';
import { StyleDictionaryByPseudoNameByField } from '../style-dictionary-by-pseudo-name-by-field';
import { StyleOptions } from '../style-options';
import { RawInitOptionsStyles } from './raw-init-options-styles';
import { RawStyleDictionaryByPseudoName } from './raw-style-dictionary-by-pseudo-name';
import { RawStyleDictionaryByPseudoNameByField } from './raw-style-dictionary-by-pseudo-name-by-field';

const PSEUDO_STYLE_REGEX = /^:([a-z]+)$/;
const VALID_PSEUDO_KEYS = ['focus', 'hover', 'placeholder'];

export class StyleOptionsParser implements Parser<RawInitOptionsStyles, StyleOptions> {
  constructor(private readonly _settings: Settings) { }

  private static _normalizeKey(key: string): string {
    // Convert to valid css property (e.g "backgroundColor" to "background-color")
    return key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  private static _isObject(value: any): boolean {
    const valueAsString = Object.prototype.toString.call(value);
    return (
      value !== undefined &&
      value !== null &&
      typeof value === 'object' &&
      valueAsString !== '[object RegExp]' &&
      valueAsString !== '[object Date]'
    );
  }

  private static _mergeStyleDictionaries(src1: StyleDictionary, src2: StyleDictionary): StyleDictionary {
    const result: { [style: string]: string } = {};

    Object.keys(src1.styles).forEach(key => {
      result[key] = src1.styles[key];
    });

    Object.keys(src2.styles).forEach(key => {
      result[key] = src2.styles[key];
    });

    return new StyleDictionary(result);
  }

  parse(rawInitOptionsStyles: RawInitOptionsStyles | undefined): StyleOptions {
    const result = new StyleOptions();

    if (!rawInitOptionsStyles) {
      return result;
    }

    result.body = this._parseStyleDictionaryByPseudoName(rawInitOptionsStyles.body);
    result.cardType = this._parseStyleDictionaryByPseudoName(rawInitOptionsStyles.cardType);
    result.container = this._parseStyleDictionaryByPseudoNameByField(rawInitOptionsStyles.container);
    result.label = this._parseStyleDictionaryByPseudoNameByField(rawInitOptionsStyles.label);
    result.input = this._parseStyleDictionaryByPseudoNameByField(rawInitOptionsStyles.input);
    result.select = this._parseStyleDictionaryByPseudoNameByField(rawInitOptionsStyles.select);
    result.validation = this._parseStyleDictionaryByPseudoNameByField(rawInitOptionsStyles.validation);
    result.errorLabel = this._parseStyleDictionaryByPseudoNameByField(rawInitOptionsStyles.errorLabel);
    result.errorInput = this._parseStyleDictionaryByPseudoNameByField(rawInitOptionsStyles.errorInput);

    return result;
  }

  private _parseStyleDictionary(rawStyles: string | RawStyleDictionaryByPseudoName | undefined): StyleDictionary {
    const styles = new StyleDictionary();

    if (rawStyles === undefined || rawStyles === null || !StyleOptionsParser._isObject(rawStyles)) {
      return styles;
    }

    Object.keys(rawStyles).forEach(key => {
      const style = (rawStyles as any)[key];

      if (style === undefined || style === null || StyleOptionsParser._isObject(style)) {
        return;
      }

      styles.add(StyleOptionsParser._normalizeKey(key), style);
    });

    return styles;
  }

  private _parseStyleDictionaryByPseudoName(
    rawStyleDictionary: RawStyleDictionaryByPseudoName | undefined,
  ): StyleDictionaryByPseudoName {
    const result = new StyleDictionaryByPseudoName();

    if (rawStyleDictionary === undefined || rawStyleDictionary === null) {
      return result;
    }

    Object.keys(rawStyleDictionary).forEach(key => {
      const style = rawStyleDictionary[key];

      if (style === undefined || style === null || style === '') {
        return;
      }

      const match = key.match(PSEUDO_STYLE_REGEX);

      if (match) {
        const pseudoKey = match[1];

        if (VALID_PSEUDO_KEYS.indexOf(pseudoKey) === -1) {
          return;
        }

        (result as any)[pseudoKey] = this._parseStyleDictionary(rawStyleDictionary[key]);
      } else {
        if (style === undefined || style === null || StyleOptionsParser._isObject(style)) {
          return;
        }

        result.base.add(StyleOptionsParser._normalizeKey(key), style as string);
      }
    });

    return result;
  }

  private _parseStyleDictionaryByPseudoNameByField(
    rawStyleDictionaryByField: RawStyleDictionaryByPseudoNameByField | undefined,
  ): StyleDictionaryByPseudoNameByField {
    const result = new StyleDictionaryByPseudoNameByField();

    if (!rawStyleDictionaryByField) {
      return result;
    }

    const defaultStyles = this._parseStyleDictionaryByPseudoName(rawStyleDictionaryByField.default);

    this._settings.fieldNames.forEach(field => {
      const fieldStyles = this._parseStyleDictionaryByPseudoName(rawStyleDictionaryByField[field]);

      const finalStyles = new StyleDictionaryByPseudoName();

      finalStyles.base = StyleOptionsParser._mergeStyleDictionaries(defaultStyles.base, fieldStyles.base);
      finalStyles.focus = StyleOptionsParser._mergeStyleDictionaries(defaultStyles.focus, fieldStyles.focus);
      finalStyles.hover = StyleOptionsParser._mergeStyleDictionaries(defaultStyles.hover, fieldStyles.hover);
      finalStyles.placeholder = StyleOptionsParser._mergeStyleDictionaries(
        defaultStyles.placeholder,
        fieldStyles.placeholder,
      );

      (result as any)[field] = finalStyles;
    });

    return result;
  }

}
