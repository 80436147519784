import { BuiltInHostListenerCallback } from './built-in-host-listener-callback';
import { CustomHostListenerCallback } from './custom-host-listener-callback';
import { HostListenerCallbackContext } from './host-listener-callback-context';
import { HostListenerRegistry } from './host-listener-registry';

export class DefaultHostListenerRegistry implements HostListenerRegistry {
  private _dictionary: ListenerDictionary;

  constructor() {
    this._dictionary = {
      builtIn: {},
      custom: {},
    };
  }

  addBuiltIn<TMessage>(type: string, callback: BuiltInHostListenerCallback<TMessage>) {
    if (!this._dictionary.builtIn[type]) {
      this._dictionary.builtIn[type] = [];
    }

    this._dictionary.builtIn[type].push(callback);
  }

  addCustom<TMessage>(type: string, callback: CustomHostListenerCallback<TMessage>) {
    if (!this._dictionary.custom[type]) {
      this._dictionary.custom[type] = [];
    }

    this._dictionary.custom[type].push(callback);
  }

  clear() {
    this._dictionary = {
      builtIn: {},
      custom: {},
    };
  }

  trigger(context: HostListenerCallbackContext) {
    const type = context.message.type;

    if (this._dictionary.builtIn[type]) {
      this._dictionary.builtIn[type].forEach(listener => {
        const boundListener = listener.bind(null) as BuiltInHostListenerCallback;
        boundListener(context);
      });
    }

    if (this._dictionary.custom[type]) {
      this._dictionary.custom[type].forEach(listener => {
        const boundListener = listener.bind(null) as CustomHostListenerCallback;
        boundListener(context.message.body);
      });
    }
  }
}

interface ListenerDictionary {
  builtIn: { [messageType: string]: BuiltInHostListenerCallback[] };
  custom: { [messageType: string]: CustomHostListenerCallback[] };
}
