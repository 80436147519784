import { LocalizationOptions } from '../localization-options';
import { Parser } from '../parser';
import { StyleOptions } from '../style-options';
import { LocalizationParserOptions } from './localization-parser-options';
import { RawInitOptions } from './raw-init-options';
import { RawInitOptionsLocalization } from './raw-init-options-localization';
import { RawInitOptionsStyles } from './raw-init-options-styles';
import { KlarnaOptions } from '../klarna-options';
import { RawInitOptionsKlarna } from './raw-init-options-klarna';
import { RawInitOptionsWallet } from "./raw-init-options-wallet";
import { WalletOptions } from '../wallet-options';
import { EndUserInfo } from '../end-user-info';

export class InitOptions {
  readonly container: HTMLElement;
  readonly debug: boolean;
  readonly merchantKey: string;
  readonly singleColumnLayoutBreakpoint: number;
  readonly acceptInternationalPostalCodes: boolean;
  readonly requireCardholderName: boolean;
  readonly showPlaceholders: boolean;
  readonly styles: StyleOptions;
  readonly localization: LocalizationOptions;
  readonly autoFocusOnFirstField: boolean;
  readonly klarna: KlarnaOptions;
  readonly wallet: WalletOptions;

  constructor(
    styleOptionsParser: Parser<RawInitOptionsStyles, StyleOptions>,
    localizationParser: Parser<RawInitOptionsLocalization, LocalizationOptions, LocalizationParserOptions>,
    klarnaOptionsParser: Parser<RawInitOptionsKlarna, KlarnaOptions>,
    walletOptionsParser: Parser<RawInitOptionsWallet, WalletOptions>,
    rawInitOptions: RawInitOptions | undefined,
    readonly endUserInfo: EndUserInfo,
  ) {
    if (!rawInitOptions) {
      throw new Error('No options were passed in.');
    }

    InitOptions._ensureValidity(rawInitOptions);

    this.container = rawInitOptions.container;
    this.debug = rawInitOptions.debug || false;
    this.merchantKey = rawInitOptions.merchantKey;
    this.singleColumnLayoutBreakpoint = rawInitOptions.singleColumnLayoutBreakpoint || 768;
    this.acceptInternationalPostalCodes = rawInitOptions.acceptInternationalPostalCodes || false;
    this.autoFocusOnFirstField = rawInitOptions.autoFocusOnFirstField === null || rawInitOptions.autoFocusOnFirstField === undefined
      ? false : rawInitOptions.autoFocusOnFirstField;
    this.requireCardholderName =
      rawInitOptions.requireCardholderName === null || rawInitOptions.requireCardholderName === undefined
        ? true
        : rawInitOptions.requireCardholderName;
    this.showPlaceholders =
      rawInitOptions.showPlaceholders === null || rawInitOptions.showPlaceholders === undefined
        ? true
        : rawInitOptions.showPlaceholders;
    this.styles = styleOptionsParser.parse(rawInitOptions.styles);
    this.localization = localizationParser.parse(rawInitOptions.localization, {
      showPlaceholders: this.showPlaceholders,
    });
    this.klarna = klarnaOptionsParser.parse(rawInitOptions.klarna);
    this.wallet = walletOptionsParser.parse(rawInitOptions.wallet);
  }

  private static _ensureValidity(rawInitOptions: RawInitOptions) {
    if (!rawInitOptions.container) {
      throw new Error('Property "container" is required.');
    }

    if (!rawInitOptions.merchantKey) {
      throw new Error('Property "merchantKey" is required.');
    }
  }
}
