import { StyleDictionaryByPseudoName } from './style-dictionary-by-pseudo-name';
import { StyleDictionaryByPseudoNameByField } from './style-dictionary-by-pseudo-name-by-field';

export class StyleOptions {
  readonly $className = 'StyleOptions';
  body: StyleDictionaryByPseudoName;
  cardType: StyleDictionaryByPseudoName;
  container: StyleDictionaryByPseudoNameByField;
  label: StyleDictionaryByPseudoNameByField;
  input: StyleDictionaryByPseudoNameByField;
  select:StyleDictionaryByPseudoNameByField;
  validation: StyleDictionaryByPseudoNameByField;
  errorLabel: StyleDictionaryByPseudoNameByField;
  errorInput: StyleDictionaryByPseudoNameByField;

  constructor() {
    this.body = new StyleDictionaryByPseudoName();
    this.cardType = new StyleDictionaryByPseudoName();
    this.container = new StyleDictionaryByPseudoNameByField();
    this.label = new StyleDictionaryByPseudoNameByField();
    this.input = new StyleDictionaryByPseudoNameByField();
    this.select = new StyleDictionaryByPseudoNameByField();
    this.validation = new StyleDictionaryByPseudoNameByField();
    this.errorLabel = new StyleDictionaryByPseudoNameByField();
    this.errorInput = new StyleDictionaryByPseudoNameByField();
  }
}
