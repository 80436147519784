import { Message } from './message';
import { MessageQueue } from './message-queue';
import { SendMessageFn } from './send-message-fn';

export class DefaultMessageQueue implements MessageQueue {
  private _sendMessageFn: SendMessageFn | null;
  private readonly _queue: any[];

  constructor() {
    this._sendMessageFn = null;
    this._queue = [];
  }

  setSendMessageFn(sendMessageFn: SendMessageFn) {
    this._sendMessageFn = sendMessageFn;
    this._processQueue();
  }

  add<TBody>(message: Message<TBody>) {
    this._queue.push(message);
    this._processQueue();
  }

  private _processQueue() {
    if (!this._sendMessageFn || !this._queue.length) {
      return;
    }

    const message = this._queue.splice(0, 1)[0];

    this._sendMessageFn(message);

    this._processQueue();
  }
}
