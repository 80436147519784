export class KlarnaOptions {
  readonly $classname = 'KlarnaOptions';
  amount: number;
  returnUrl: string;


  constructor() {
    this.amount = 0;
    this.returnUrl = '';
  }
}
