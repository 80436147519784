import { LocalizationByField } from './localization-by-field';
import { LocalizationValidation } from './localization-validation';

export class LocalizationOptions {
  readonly $className = 'LocalizationOptions';
  label: LocalizationByField;
  placeholder: LocalizationByField;
  validation: LocalizationValidation;

  constructor() {
    this.label = new LocalizationByField();
    this.placeholder = new LocalizationByField();
    this.validation = new LocalizationValidation();
  }
}
