export enum FrameMessageType {
  AddPaymentMethodFailed = 'add-payment-method-failed',
  AddPaymentMethodSucceeded = 'add-payment-method-succeeded',
  HeightChanged = 'height-changed',
  InitAck = 'init-ack',
  SwiperLostFocus = 'swiper-lost-focus',
  ValidityChanged = 'validity-changed',
  ModeChanged = 'mode-changed',
  KlarnaSaleSucceeded = 'klarna-sale-succeeded',
  KlarnaSaleFailed = 'klarna-sale-failed',
  MerchantInit = 'merchant-init',
  MerchantInitFailed = 'merchant-init-failed'
}
