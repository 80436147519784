import { FrameMessageType } from '../shared/frame-message-type';
import { HeightChangedMessage } from '../shared/frame-messages/height-changed-message';
import { KlarnaSaleSucceededMessage } from "../shared/frame-messages/klarna-sale-succeeded-message";
import { ModeChangedMessage } from '../shared/frame-messages/mode-changed-message';
import { HostMessage } from '../shared/host-message';
import { HostMessageType } from '../shared/host-message-type';
import { SwipeDataMessage } from '../shared/host-messages/swipe-data-message';
import { MessageQueue } from '../shared/message-queue';
import { Mode } from '../shared/mode';
import { SwipeKeyHelper } from '../shared/swipe-key-helper.service';
import { HostListenerRegistry } from './host-listener-registry';

export class BuiltInListeners {
  private _mode = Mode.Undefined;
  private readonly _swipeKeyHelper = new SwipeKeyHelper();

  constructor(private readonly _messageQueue: MessageQueue, listenerRegistry: HostListenerRegistry) {
    listenerRegistry.addBuiltIn<HeightChangedMessage>(FrameMessageType.HeightChanged, context => {
      context.iframe.style.height = context.message.body.height + 'px';
    });

    listenerRegistry.addBuiltIn<ModeChangedMessage>(FrameMessageType.ModeChanged, context => {
      this._mode = context.message.body.mode;
    });

    listenerRegistry.addBuiltIn<KlarnaSaleSucceededMessage>(FrameMessageType.KlarnaSaleSucceeded, context => {
      if(context.message.body.redirectUrl) {
        window.location.href = context.message.body.redirectUrl;
      }
    })

    window.document.addEventListener('keydown', this.onKeyDown.bind(this));
    window.document.addEventListener('keyup', this.onKeyUp.bind(this));
    window.document.addEventListener('paste', (this.onPaste as EventListener).bind(this));
  }

  private get isSwipeMode(): boolean {
    return this._mode === Mode.CcSwiper;
  }

  destroy() {
    window.document.removeEventListener('keydown', this.onKeyDown);
    window.document.removeEventListener('keyup', this.onKeyUp);
    window.document.removeEventListener('paste', this.onPaste as EventListener);
  }

  private sendSwipeData(data: string | null, isPaste?: boolean) {
    if (!data) return;

    this._messageQueue.add(
      new HostMessage<SwipeDataMessage>(HostMessageType.SwipeData, {
        data: data,
        isPaste: isPaste,
      }),
    );
  }

  private onKeyDown(e: KeyboardEvent) {

    if (!this.isSwipeMode) return;
    // allow copy paste
    if((e.key === 'v' || e.key === 'c') && e.ctrlKey)
      return;


    // we don't want the browser to confuse our swipe data with any hotkeys or shortcuts
    e.preventDefault();
    e.cancelBubble = true;

    const data = this._swipeKeyHelper.getKeyData(e);
    this.sendSwipeData(data);
  }

  private onKeyUp(e: KeyboardEvent) {
    if (!this.isSwipeMode) return;

    e.preventDefault();
    e.cancelBubble = true;

  }

  private onPaste(e: UIEvent) {
    if (!this.isSwipeMode) return;

    e.preventDefault();
    e.cancelBubble = true;

    const data = this._swipeKeyHelper.getPasteData(e);
    this.sendSwipeData(data, true);
  }
}
