import { InitOptions } from '../init-options/init-options';
import { LocalizationOptions } from '../localization-options';
import { StyleOptions } from '../style-options';
import { KlarnaOptions } from "../klarna-options";
import { WalletOptions } from '../wallet-options';
import { EndUserInfo } from '../end-user-info';

export class InitMessage {
  readonly debug: boolean;
  readonly merchantKey: string;
  readonly acceptInternationalPostalCodes: boolean;
  readonly singleColumnLayoutBreakpoint: number;
  readonly requireCardholderName: boolean;
  readonly styles: StyleOptions;
  readonly localization: LocalizationOptions;
  readonly endUserInfo: EndUserInfo;
  readonly autoFocusOnFirstField: boolean;
  readonly klarna: KlarnaOptions;
  readonly wallet: WalletOptions;

  constructor(initOptions: InitOptions) {
    this.debug = initOptions.debug;
    this.merchantKey = initOptions.merchantKey;
    this.acceptInternationalPostalCodes = initOptions.acceptInternationalPostalCodes;
    this.singleColumnLayoutBreakpoint = initOptions.singleColumnLayoutBreakpoint;
    this.requireCardholderName = initOptions.requireCardholderName;
    this.styles = initOptions.styles;
    this.localization = initOptions.localization;
    this.endUserInfo = initOptions.endUserInfo;
    this.autoFocusOnFirstField = initOptions.autoFocusOnFirstField;
    this.klarna = initOptions.klarna;
    this.wallet = initOptions.wallet;
  }
}
