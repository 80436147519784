export class LocalizationByField {
  cardholderName: string;
  cardNumber: string;
  expiration: string;
  cvv: string;
  postalCode: string;
  routingNumber: string;
  accountNumber: string;
  accountHolder: string;
  accountType: string;
  companyName: string;
  emailAddress: string;
  countryCode: string;
  fullName: string;

  constructor() {
    this.cardholderName = '';
    this.cardNumber = '';
    this.expiration = '';
    this.cvv = '';
    this.postalCode = '';
    this.routingNumber = '';
    this.accountNumber = '';
    this.accountHolder = '';
    this.accountType = '';
    this.companyName = '';
    this.emailAddress = '';
    this.countryCode = '';
    this.fullName = '';
  }
}
